



















import 'animate.css';
import { AppTs } from '@/app/AppTs.ts';

export default {
    extends: AppTs,
    data() {
        return {
            showVersionPanel: true, // Set to true by default
        };
    },
    created() {
        // Add a navigation guard to hide version panel for specific routes
        this.$router.beforeEach((to, from, next) => {
            if (to.name === 'privacy' || to.name === 'terms') {
                this.showVersionPanel = false;
            } else {
                this.showVersionPanel = true;
            }
            next();
        });
    },
};
